// general definitions
//$text-keyboard-width: 735px; // too big: 643px Breite in moni::tool
$text-keyboard-width: 643px; // too big: 643px Breite in moni::tool
$number-keyboard-width: 211px;

$key-padding-hor: 5px;
$key-width: calc(($text-keyboard-width - $key-padding-hor - 5px) / 14.5) - $key-padding-hor;

$key-padding-ver: 4px;
$key-height: 33px;

.containertext,
.containertextarea {
  width: $text-keyboard-width;
  .write {
    width: $text-keyboard-width - 18px !important;
  }
}
.keyboardcontainer.containertext {
  // height: 280 px
  height: 5 * ($key-height + $key-padding-ver) + 50px;
}
.keyboardcontainer.containertextarea {
  // height: 335px;
  height: 5 * ($key-height + $key-padding-ver) + 100px;

  .write {
    margin-bottom: 0;
  }
}
.keyboardcontainer.containernumber {
  // height: 235px;
  height: 4 * ($key-height + $key-padding-ver) + 50px;
  width: $number-keyboard-width;

  .write {
    width: $number-keyboard-width - 16px !important;
  }
  li {
    width: 45px; // keep this constant, do not use $key-width
  }
  .size-2letter {
    width: 95px;
  }

  .cancel {
    font-size: 15px;
  }
}

.keyboardcontainer {
  display: none; /* hidden per default */
  color: black; /* black font in keys */

  margin: 0;
  padding: 5px 0 10px 7px;
  font-size: 16px;
  list-style: none;
  background-color: #253a49;
  z-index: 2000; /* above #shadow-bottom */
  border: 1px solid #f9f9f9;
  border-radius: 3px;

  * {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    float: left;
    margin: 0 $key-padding-hor $key-padding-ver 0;
    width: $key-width;
    height: $key-height;
    line-height: $key-height - 5px;
    text-align: center;
    padding-top: 4px;
    background: #fff;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  .label {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 2px;
  }

  .write {
    margin: 0 0 5px;
    padding: 5px;
    font: 1em/1.5 Verdana, Sans-Serif;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
    resize: none;
    background: #ffffff;
    color: black;
  }

  .left {
    clear: left;
  }

  .size-tab,
  .size-delete {
    width: 1.5 * $key-width;
  }
  .size-capslock,
  .size-return {
    width: 1.8 * $key-width;
  }
  .size-left-shift,
  .size-cancel,
  .size-accept,
  .size-right-shift,
  .size-2letter {
    width: 2 * $key-width + $key-padding-hor;
  }
  .shift.size-2letter {
    width: 2.25 * $key-width + $key-padding-hor;
  }
  .size-space {
    width: $text-keyboard-width - 2 * (2 * $key-width + $key-padding-hor) - 27px;
  }

  .lastitem {
    margin-right: 0;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .on {
    display: none;
  }

  .highlight {
    animation: highlight 0.2s;
  }
}

// hide white background and put it back then
@keyframes highlight {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.keyboardWithTitle {
  padding-bottom: 30px;
}
