.fileUploadDiv {
  height: 100%;
  background: white;
  padding: 10px;
  margin: 0px 20px 10px 0px;
  border-radius: 4px;
  border: 1px grey dashed;
  height: 200px;
  z-index: 1000;

  .file {
    font: normal bold normal 16px/20px Neue Haas Grotesk Text Pro;
    margin-top: 30px;

    input[type="file"] {
      display: none;
    }

    .fileHint {
      font: normal normal normal 14px/20px Neue Haas Grotesk Text Pro;
      margin-top: 5px;
    }

    span.link {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
